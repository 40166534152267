// extracted by mini-css-extract-plugin
export var box = "preview-module--box--oZ2fG";
export var transform = "preview-module--transform--C2OoY";
export var galleryWrapper = "preview-module--galleryWrapper--14V-7";
export var galleryScroll = "preview-module--galleryScroll--m78va";
export var layoutContainer = "preview-module--layoutContainer--38COT";
export var itemContent = "preview-module--itemContent--YsDUO";
export var item = "preview-module--item--CANPn";
export var menuTitle = "preview-module--menuTitle--fssu0";
export var header = "preview-module--header--ghPxA";
export var leftbar = "preview-module--leftbar--jfYdk";
export var main = "preview-module--main--KnZSI";
export var rightbar = "preview-module--rightbar--L6pBV";
export var footer = "preview-module--footer--21p1P";